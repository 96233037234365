import React, { useState, useRef, useContext } from "react";
import { Cascader, Menu, Button, Checkbox, Input } from "antd";
import { Typography } from "antd";
import { deepCopy, PGTypeMapper, uuidv4, MySQLTypeMapper, SnowflakeTypeMapper, ESTypeMapper } from "../../utils/utils.js";
import UserContext from "../../Contexts.js";

import { getValidOperations, getValidCollectionsAndFields } from "../../utils/utils.js";
import { generateQuery } from '../../utils/sql_generator.js';
import { rootURI } from '../../utils/common_definitions.js'
import { FilterPopover } from "./FilterPopover.jsx";

const { Text } = Typography;

export function QuestionNode(props) {
  const nodeIndex = props.nodeIndex;
  const schema = props.schema;
  const question = props.question;
  const datasource = props.datasource;
  const node = question.nodes[ nodeIndex ];
  const cascaderRef = useRef(null);
  const { userObject, setUserObject } = useContext(UserContext);

  const [ cascaderDisabled, setCascaderDisabled ] = useState(false);
  const [ options, setOptions ] = useState(generateNodeMenu(nodeIndex, question, schema));
  const [ cascaderValue, setCascaderValue ] = useState(getNodeValueFromOptions(node, options));
  const [ searchString, setSearchString ] = useState("");
  const [ notFoundContent, setNotFoundContent ] = useState("Press Enter to ask your question");
  // states: waiting for user to press Enter, searching, displaying results


  function getNodeValueFromOptions(node, options) {
    if (node.operation === "") {
      return "";
    }

    for (const option of options) {
      if (option.operation && option.operation === node.operation &&
        option.collection.namespace === node.collection.namespace && option.collection.table === node.collection.table) {
        if (node.selectedField && option.selectedField !== node.selectedField) continue;
        if (node.transformation && option.transformation !== node.transformation) continue;

        return option.value;
      }
    }

    for (const option2 of options) {
      if (option2.children?.length > 0) {
        const match = getNodeValueFromOptions(node, option2.children);
        if (match) {
          return [ option2.value, match ].flat();
        }
      }
    }
  }

  // Function to get the full name
  function getTableFullName(obj, tableCounts) {
    if (tableCounts[ obj.table ] > 1) {
      return `${obj.namespace}.${obj.table}`;
    } else {
      return obj.table;
    }
  }


  // It would be nice for this code to be in Question instead
  // but we cannot pass nodeIndex so we might not be able to tell between two nodes
  // in the case of a self join for example
  function handleNodeChangeWithIndex(nodeIndex, props) {
    return function handleNodeChange(values, selectedOptions) {
      // If the node was cleared set all values to ''
      let newNode = {};

      if (values === undefined) {
        newNode = { operation: "", collection: { namespace: "", table: "" }, selectedField: "" };
      } else {
        let selectedOptionInfo = selectedOptions[ selectedOptions.length - 1 ];
        newNode[ "operation" ] = selectedOptionInfo.operation;
        newNode[ "collection" ] = selectedOptionInfo.collection;
        newNode[ "path" ] = selectedOptionInfo.path;
        if ([ "for each", "sum of", "number of", "average" ].includes(newNode.operation)) {
          // newNode[ "firstDifferentTable" ] = selectedOptionInfo?.firstDifferentTable;
          newNode[ "selectedField" ] = selectedOptionInfo?.selectedField;
          newNode[ "transformation" ] = selectedOptionInfo?.transformation;
        }
      }

      // Edit the existing node's operation and remove all nodes after
      let newNodeList = props.question.nodes.slice(0, nodeIndex).concat([ newNode ]);

      if (newNode.operation !== "") {
        newNodeList.push({
          operation: "",
          collection: { namespace: "", table: "" },
          selectedField: "",
        });
      }

      let newQuestion = { ...props.question, nodes: newNodeList };
      setCascaderValue(values);
      console.log("Frontend query:");
      console.log(generateQuery(newQuestion, schema).toString());
      props.updateQuestion(newQuestion);
    }
  }

  function isValidFieldForOperation(fieldType, operation) {
    const defaultOperations = [ "for each", "number of" ];
    const numberOperations = [ "average", "sum of" ];


    let validOperations = defaultOperations;
    if (fieldType === "number") {
      validOperations = [ ...defaultOperations, ...numberOperations ];
    } else if (fieldType === "date" && operation !== "sum of" && operation !== "average") {
      // Assuming "date" type is only valid for "for each" and "number of" and not "sum of" or "average"
      validOperations = [ "for each", "number of" ];
    }

    return validOperations.includes(operation);
  }

  function hasValidFieldsForOperation(table, operation, schema, namespace) {
    if (operation === "list of" || operation === "for each" || operation === "number of") {
      return true;
    }

    const direct_fields = schema.namespaces[ namespace ].tables[ table ].direct_fields;
    const primary_keys = schema.namespaces[ namespace ].tables[ table ].primary_key;

    // Extract foreign keys
    const outbound_relationships = schema.namespaces[ namespace ].tables[ table ].outbound_relationships || {};
    const foreign_keys = [];
    for (const targetNamespace in outbound_relationships) {
      for (const targetTable in outbound_relationships[ targetNamespace ]) {
        outbound_relationships[ targetNamespace ][ targetTable ].forEach(rel => {
          foreign_keys.push(rel.source_column);
        });
      }
    }

    for (const fieldObj of direct_fields) {
      const fieldName = Object.keys(fieldObj)[ 0 ];  // Get the key (which is the field name)
      const originalFieldType = fieldObj[ fieldName ];  // Get the value (which is the field type)


      // Map the database-specific field type to a standardized type
      let fieldType = "";
      switch (datasource.db_type) {
        case "PostgreSQL":
          fieldType = PGTypeMapper(originalFieldType);
          break;
        case "MySQL":
          fieldType = MySQLTypeMapper(originalFieldType);
          break;
        case "Snowflake":
          fieldType = SnowflakeTypeMapper(originalFieldType);
          break;
        case "FEC":
          fieldType = ESTypeMapper(originalFieldType);

        default:
          fieldType = originalFieldType;  // or break;
          break;
      }

      // Skip numeric primary keys or foreign keys
      if ((fieldType === "number" && primary_keys.includes(fieldName)) || foreign_keys.includes(fieldName)) {
        continue;
      }

      if (isValidFieldForOperation(fieldType, operation)) {
        return true;  // At least one valid field found
      }
    }
    return false;
  }


  function generateNodeMenu(nodeIndex, question, schema) {
    const menu = [];
    const validOperations = getValidOperations(nodeIndex, question);

    if (nodeIndex === 0) {
      validOperations.reverse();
    }

    for (const operation of validOperations) {
      const { validFieldsByCollection, validCollections } = getValidCollectionsAndFields(operation, nodeIndex, question, schema, datasource.namespaces);
      const uniqueVal1 = uuidv4();
      const operationOption = {
        value: uniqueVal1,
        key: uniqueVal1,
        label: getOperationLabelPrefix(operation, nodeIndex, question) + operation,
        children: [],
      };

      if (operation === "list of" && nodeIndex === 0) {
        for (const [ collectionNamespace, collectionTable ] of validCollections) {
          const uniqueVal = uuidv4();
          let collection = { 'namespace': collectionNamespace, 'table': collectionTable };
          const menuOption = {
            value: uniqueVal,
            operation,
            nodeIndex,
            label: `all ${collection.table}`,
            key: uniqueVal,
            collection,
            path: [ { source: collection } ],
          };
          menu.push(deepCopy(menuOption));
        }
        continue;
      }

      for (const [ collectionNamespace, collectionTable ] of validCollections) {
        const primary_keys = schema.namespaces[ collectionNamespace ].tables[ collectionTable ].primary_key || [];

        // If you also want to handle foreign keys:
        const outbound_relationships = schema.namespaces[ collectionNamespace ].tables[ collectionTable ].outbound_relationships || {};
        const foreign_keys = [];
        for (const targetNamespace in outbound_relationships) {
          for (const targetTable in outbound_relationships[ targetNamespace ]) {
            outbound_relationships[ targetNamespace ][ targetTable ].forEach(rel => {
              foreign_keys.push(rel.source_column);
            });
          }
        }

        if (!hasValidFieldsForOperation(collectionTable, operation, schema, collectionNamespace)) {
          continue;
        }

        let collection = { 'namespace': collectionNamespace, 'table': collectionTable };
        let collectionPaths = [ [ { source: collection } ] ];
        let label = collectionTable;

        if (nodeIndex > 0) {
          let previousNodeCollection = collection;

          if (operation === "for each") {
            for (let i = nodeIndex - 1; i >= 0; i--) {
              if ([ "list of", "sum of", "number of", "average" ].includes(question.nodes[ i ].operation)) {
                previousNodeCollection = question.nodes[ i ].collection;
                break;
              }
            }
          } else if ([ "list of", "sum of", "number of", "average" ].includes(operation)) {
            for (let i = nodeIndex - 1; i >= 0; i--) {
              if (question.nodes[ i ].operation === "list of") {
                previousNodeCollection = question.nodes[ i ].collection;
                break;
              }
            }
          }

          const allPaths = props.userInfo.datasources[ question.datasource_id ].allPaths;
          collectionPaths = allPaths[ previousNodeCollection.namespace ][ previousNodeCollection.table ][ collection.namespace ][ collection.table ];
        }

        if (operation === "for each") {
          collectionPaths.sort((a, b) => a.length - b.length);
        }

        for (const currentPath of collectionPaths) {
          let uniqueVal2 = uuidv4();
          let collectionChild = {
            key: uniqueVal2,
            value: uniqueVal2,
            collection,
            path: deepCopy(currentPath),
            firstDifferentTable: {},
          };
          const fieldList = [];

          if (collectionPaths.length > 1) {
            for (let pathIdx = 0; pathIdx < currentPath.length; pathIdx++) {
              if (collectionPaths.some(path => path[ pathIdx ].target.namespace !== currentPath[ pathIdx ].target.namespace || path[ pathIdx ].target.table !== currentPath[ pathIdx ].target.table)) {
                collectionChild.firstDifferentTable = currentPath[ pathIdx ].target;
                break;
              }
              if (collectionPaths.some(path => path[ pathIdx ].source.namespace !== currentPath[ pathIdx ].source.namespace || path[ pathIdx ].source.table !== currentPath[ pathIdx ].source.table)) {
                collectionChild.firstDifferentTable = currentPath[ pathIdx ].source;
                break;
              }
            }
            label = getTableFullName(collectionChild.firstDifferentTable, props.userInfo.datasources[ question.datasource_id ].tableCounts) + "\'s " + collectionTable;
          }

          collectionChild.label = label;

          if (validFieldsByCollection &&
            validFieldsByCollection[ collectionNamespace ] &&
            validFieldsByCollection[ collectionNamespace ][ collectionTable ]
          ) {
            for (const field of validFieldsByCollection[ collectionNamespace ][ collectionTable ]) {
              const originalFieldType = Object.values(field)[ 0 ];
              let fieldType = "";
              switch (datasource.db_type) {
                case "PostgreSQL":
                  fieldType = PGTypeMapper(originalFieldType);
                  break;
                case "MySQL":
                  fieldType = MySQLTypeMapper(originalFieldType);
                  break;
                case "Snowflake":
                  fieldType = SnowflakeTypeMapper(originalFieldType);
                  break;
                default:
                  break;
              }
              // console.log("Field type:" + fieldType);
              const fieldName = Object.keys(field)[ 0 ];
              if ((operation === "sum of" || operation === "average") && (primary_keys.includes(fieldName) || foreign_keys.includes(fieldName))) {
                continue; // Skip processing this field
              }
              // Only add the field if it is compatible with the operation
              if (!isValidFieldForOperation(fieldType, operation)) {
                continue;
              }
              // console.log("Field name:" + fieldName);
              const fieldValue = uuidv4();
              const fieldChild = {
                value: fieldValue,
                key: fieldValue,
                label: fieldName,
                selectedField: fieldName,
                operation,
                collection,
                path: currentPath,
                firstDifferentTable: collectionChild.firstDifferentTable,
              };

              if (fieldType === "number" && operation === "for each" && !primary_keys.includes(fieldName)) {
                let [ k1, k2, k3 ] = [ uuidv4(), uuidv4(), uuidv4() ];
                fieldChild.children = [
                  { ...fieldChild, label: "exact", aggregation: "exact", key: k1, value: k1 },
                  { ...fieldChild, label: "split into 10 intervals", aggregation: "split into 10 intervals", key: k2, value: k2 },
                  { ...fieldChild, label: "split into 100 intervals", aggregation: "split into 100 intervals", key: k3, value: k3 },
                ];
                delete fieldChild.operation;
              }

              if (fieldType === "date" && operation === "for each") {
                let [ k1, k2, k3, k4, k5 ] = [ uuidv4(), uuidv4(), uuidv4(), uuidv4(), uuidv4() ];
                fieldChild.children = [
                  { ...fieldChild, label: "exact", aggregation: "exact", key: k1, value: k1 },
                  { ...fieldChild, label: "by year", aggregation: "by year", key: k2, value: k2 },
                  { ...fieldChild, label: "by month", aggregation: "by month", key: k3, value: k3 },
                  { ...fieldChild, label: "by week", aggregation: "by week", key: k4, value: k4 },
                  { ...fieldChild, label: "by day", aggregation: "by day", key: k5, value: k5 },
                ];
                delete fieldChild.operation;
              }

              fieldList.push(deepCopy(fieldChild));
            }
          } else {
            collectionChild.operation = operation;
          }

          collectionChild.children = deepCopy(fieldList);
          operationOption.children.push(deepCopy(collectionChild));
        }
      }

      operationOption.children.sort((a, b) => a.path.length - b.path.length);
      menu.push(deepCopy(operationOption));
    }

    console.log(menu);
    return menu;
  }

  function getOperationLabelPrefix(operation, nodeIndex, question) {
    let operationLabelPrefix = "";

    if (nodeIndex > 0) {
      if (operation === "for each") {
        if (question.nodes[ nodeIndex - 1 ].operation === "for each") {
          operationLabelPrefix = 'and ';
        } else {
          operationLabelPrefix = ''
        }
      } else {
        operationLabelPrefix = 'and their ';
      }
    } else {
      operationLabelPrefix = "the ";
    }
    return operationLabelPrefix;
  }


  function displayRender(schema, question, nodeIndex, props) {
    return function (labels, selectedOptions) {
      if (question.nodes[ nodeIndex ].operation === "") {
        return [ "" ];
      }

      let dataOption = selectedOptions[ selectedOptions.length - 1 ];

      let operationLabelPrefix = getOperationLabelPrefix(question.nodes[ nodeIndex ].operation, nodeIndex, question);

      return (
        <>
          <Text>{operationLabelPrefix + dataOption.operation}</Text>
          <FilterPopover
            label={" " + (dataOption.firstDifferentTable && dataOption.firstDifferentTable.table ? getTableFullName(dataOption.firstDifferentTable, props.userInfo.datasources[ question.datasource_id ].tableCounts) + "'s " : "") + getTableFullName(dataOption.collection, props.userInfo.datasources[ question.datasource_id ].tableCounts) + (dataOption.selectedField && dataOption.selectedField !== "" ? " " + dataOption.selectedField : "")}
            option={dataOption}
            setCascaderDisabled={setCascaderDisabled}
            key={dataOption.value}
            schema={schema}
            nodeIndex={nodeIndex}
            question={question}
            datasource={datasource}
            updateQuestion={props.updateQuestion}
          ></FilterPopover>
        </>
      );

    }
  }


  function filter(inputValue, path) {
    // Normalize the input value
    const normalizedInputValue = inputValue.toLowerCase();

    // Extract the relevant data from the last element of the path
    const { operation, collection, nodeIndex } = path[ path.length - 1 ];

    // Define a helper function to check for matches
    const matchesInput = (label) => label.toLowerCase().includes(normalizedInputValue);

    // Handle the special case for the first node
    if (operation === "list of" && nodeIndex === 0) {
      // Construct the expected labels for "all" and "list of"
      const allLabel = `all ${collection.table}`;
      const listOfLabel = `list of ${collection.table}`;

      // Check if either "all [table]" or "list of [table]" matches the input value
      return matchesInput(allLabel) || matchesInput(listOfLabel);
    }

    // For other nodes, combine all path segments into a single string for easier matching
    const selectedField = path[ path.length - 1 ].selectedField || '';
    const fullPathString = `${operation} ${collection.table} ${selectedField}`;

    // Check if the full path string includes the normalized input value
    return matchesInput(fullPathString);
  }

  const handleCascaderSearch = (searchString) => {
    console.log("Searched for:");
    console.log(searchString);
    setSearchString(searchString);
  }

  const handleKeyDown = (e) => {
    if (e.code === "Enter") {
      console.log("ENTER pressed");
      setNotFoundContent("Please wait, I am thinking...");
      // actually run the search against ChatGPT
      fetch(rootURI + "/users/" + userObject.user_id + "/datasources/" + datasource.id + "/get_eloquence_sentence_from_natural_language", {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: userObject.user_token,
        },
        body: JSON.stringify({ content: searchString }),
      })
        .then((res) => res.json())
        .then((result) => {
          console.log("Result:");
          console.log(result);
          let actualData = result.choices[ 0 ].message.content;

          try {
            actualData = JSON.parse(actualData);
          } catch ({ name, message }) {
            console.log(name); // Error type
            console.log(message); // actual error message
            // we should retry, it failed to generate a valid JSON
          }

          console.log("Data returned by chatgpt:");
          console.log(actualData);

          // validate that actualData is a valid array of question nodes
          if (!Array.isArray(actualData)) {
            console.log("Not an array");
            setNotFoundContent("Sorry, I couldn't understand your question. Please try again.");
            return;
          }
          actualData.filter((node) => {
            if (!node.hasOwnProperty("collection") || !node.hasOwnProperty("operation")) {
              console.log("Not a valid node");
              setNotFoundContent("Sorry, I couldn't understand your question. Please try again.");
              return;
            }

            let validTables = Object.keys(schema);
            if (!validTables.includes(node.collection) || !operationValues.includes(node.operation) ||
              // and the table has the field
              !schema[ node.collection ].direct_fields.some(df => df.hasOwnProperty(node.selectedField))) {
              return false;
            }
          });

          let questions = [];
          // Now we need to generate the paths between each pair of nodes (if more than one node)
          let nodeLists = actualData.map((node, i) => {
            if (i === 0) {
              // simply add the path field to the first node
              return [ { ...deepCopy(node), path: [ { source: node.collection } ] } ];
            }
            // get the paths between the previous node and this one
            let allPaths = props.datasource.allPaths;
            let collectionPaths = allPaths[ actualData[ i - 1 ].collection.namespace ][ actualData[ i - 1 ].collection.table ][ actualData[ i ].collection.namespace ][ actualData[ i ].collection.table ];
            let nodesWithPath = collectionPaths.map((path) => {
              // add the path to the node
              return { ...deepCopy(node), path: path };
            });
            return nodesWithPath;
          });
          console.log(nodeLists);

          nodeLists.forEach((nodeList, i) => {
            let question = {};
            if (i === 0) {
              questions.push({ nodes: nodeList });
              question = questions[ 0 ];
            } else {
              if (nodeList.length > 1) {
                let originalQuestionCount = questions.length;

                let newQuestions = [];

                // for each item in nodeList
                nodeList.forEach((node) => {
                  // for each existing question in questions
                  for (let j = 0; j < originalQuestionCount; j++) {
                    // create a new question with the same nodes as the existing question
                    question = deepCopy(questions[ originalQuestionCount - 1 ]);
                    question.nodes.push(node);
                    newQuestions.push(question);
                  }
                });
                // remove the original questions
                questions = newQuestions;
              } else {
                questions.forEach((question) => {
                  question.nodes.push(nodeList[ 0 ]);
                });
              }
            }
          });

          console.log("questions");
          console.log(questions);
          if (questions.length > 0) {
            setNotFoundContent(
              <div style={{ display: "flex", flexDirection: "column", width: "100%", minWidth: "500px" }}>
                {questions.map((question, index) => (
                  <Button key={index} style={{ width: "100%", minWidth: "250px" }} type="text" onClick={handleQuestionClick(question)}>
                    {question.nodes.map(node => `${node.operation} ${node.collection.table}${node.selectedField ? ` ${node.selectedField}` : ''}`).join(' ')}
                  </Button>
                ))}
              </div>
            )
          } else {
            setNotFoundContent("Sorry, I couldn't understand your question. Please try again.");
          }
        })
    }
  } // end handleKeyDown


  function handleQuestionClick(question) {
    return function (onClickEvent) {
      console.log("handleQuestionClick");
      props.updateQuestion({ ...props.question, nodes: question.nodes });
    }
  }


  let style = {};
  if (nodeIndex === question.nodes.length - 1) {
    style = { width: "100%" };
  }

  const renderOptions = (inputValue, path) => {
    // console.log("renderOptions");
    // console.log(inputValue);
    // console.log(path);
    return (<Text>{path.map(node => node.label).join(" ")}</Text>)
    // if the user pressed Enter to search
    // take the result(s) from ChatGPT and render them as Question(s)
  }

  const dropdownMenuColumnStyle = {
    // height: '300px', // Adjust the height to show more options
    width: '100%', // Adjust the width to make the dropdown wider
  };

  return (
    <div style={style}>

      <Cascader
        ref={cascaderRef}
        options={options}
        disabled={cascaderDisabled}
        value={cascaderValue}
        displayRender={displayRender(schema, question, nodeIndex, props)}
        onKeyDown={handleKeyDown}
        onChange={handleNodeChangeWithIndex(nodeIndex, props)}
        onSearch={handleCascaderSearch}
        notFoundContent={notFoundContent}
        dropdownMenuColumnStyle={dropdownMenuColumnStyle}
        placeholder="Type your question or select from the menu"
        size="large"
        style={{
          width: "100%",
          minWidth: "250px",
        }}
        showSearch={{
          filter,
          limit: 100,
          render: renderOptions
        }}
      />
    </div >
  );
}
